import lax from 'lax.js';
import React from 'react';
import Cta from '../Common/Cta';
import Footer from '../Common/Footer';
import Partner from '../Common/Partner';
import About from '../HomeThree/About';
// import LastYear from '../HomeThree/LastYear';
import EventSchedules from '../HomeThree/EventSchedules';
import Info from '../HomeThree/Info';
import MainBanner from '../HomeThree/MainBanner';
import Speaker from '../HomeThree/Speaker';
import GoTop from '../Shared/GoTop';
 
class HomeThree extends React.Component {
    constructor(props) {
        super(props)
        lax.setup()
    
        document.addEventListener('scroll', function(x) {
            lax.update(window.scrollY)
        }, false)
    
        lax.update(window.scrollY)
    }
    render(){
        return (
            <React.Fragment>
                <MainBanner />
                <About />
                {/* <LastYear /> */}
                <Cta />
                <Speaker />
                <Info />
                <EventSchedules />
                <Partner />
                <Footer />
                <GoTop scrollStepInPx="50" delayInMs="16.66" />
            </React.Fragment>
        );
    }
}
 
export default HomeThree;