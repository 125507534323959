import React from 'react';
import allOneLogo from '../../assets/images/All_one_Logo_200.png';
 
class Cta extends React.Component {
    render(){
        return (
            <section className="cta-area">
                <div className="container">
                    <div className="row h-100 align-items-center">
                        <div className="col-lg-8">
                            <h3>All one in Christ</h3>
                            <span>Working Together for the Gospel</span>
                        </div>

                        <div className="col-lg-4 text-right">
                            <img src={allOneLogo} alt="All one logo" />
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default Cta;