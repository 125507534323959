import React from 'react';
import lax from 'lax.js';

class About extends React.Component {

  constructor(props) {
    super(props)
    lax.setup()

    document.addEventListener('scroll', function (x) {
      lax.update(window.scrollY)
    }, false)

    lax.update(window.scrollY)
  }

  render() {
    return (
      <section className="about-area-two ptb-120 bg-image">
        <div className="container">
          <div className="row h-100 align-items-center">
            <div className="col-lg-6">
              <div className="about-content">
                <span>Donate</span>
                <h2>Make <b>a donation</b> towards KCC</h2>

                <p>The cost to run KCC has increased significantly in the past few years, with increased costs around hire equipment, insurance, PA equipment and venue fees.</p>

                <p>You may be able to assist the KCC committee by making a donation to help offset these costs, which also helps us to keep the fees of the convention down for attendees.</p>

                <p><strong>Please Note: </strong>Donations to Katherine Christian Convention are <strong>NOT</strong> tax-deductible.</p>

                <p>You can make a donation to KCC in the following ways:
                  <ul>
                    <li>
                      <h6>via EFT</h6>
                      <p>
                        <strong>Acc Name:</strong>		Katherine Christian Convention Inc. <br />
                        <strong>BSB:</strong>			065 902<br />
                        <strong>Account Number:</strong>	0090 1636<br />
                        <strong>Reference:</strong>		Your name (if you wish)<br />
                      </p>
                    </li>
                    {/* <li>
                      <h6>via Credit or Debit Card</h6>
                    </li> */}
                    <li>
                      <h6>Cash or Cheque</h6>
                      <p>You can make these donations at the Convention in person.</p>
                    </li>
                  </ul>
                </p>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="about-image">
                <img src={require("../../assets/images/evenings_3.png")} className="about-img1" alt="Donate" />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default About;