import React from 'react';

class MainBanner extends React.Component {
    render(){
        return (
            <div className="page-title-area item-donate">
                <div className="container">
                    <h1>Donate</h1>
                </div>
            </div>
        );
    }
}
 
export default MainBanner;