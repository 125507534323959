import React from 'react';

class MainBanner extends React.Component {
  render() {
    return (
      <div className="page-title-area item-membership">
        <div className="container">
          <h1>Membership</h1>
          <span>Become a Member of KCC</span>
        </div>
      </div>
    );
  }
}

export default MainBanner;