import React from 'react';
import { Link } from 'react-router-dom';
import lax from 'lax.js';
import LaxDiv from '../Shared/LaxDiv';
 
class Speaker extends React.Component {

    constructor(props) {
        super(props)
        lax.setup()
    
        document.addEventListener('scroll', function(x) {
            lax.update(window.scrollY)
        }, false)
    
        lax.update(window.scrollY)
    }

    render(){
        return (
          <div className="container ptb-120">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title">
                  <span>2024</span>
                  <h2>Who's Speaking</h2>
                  <div className="bar"></div>
                  <LaxDiv text="Speaker" dataPreset="driftRight" />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="blog-details">
                  <p>Our theme for 2024 is <strong>'Jesus is the Living Water'</strong>. 
                    Principal of Nungalinya College, Ben van Gelderen is quite well known in the Christian Community in the NT and has been to KCC many times with Nungalinya Students. 
                  </p>

                  <blockquote className="blockquote">
                    <p>Greetings, brothers and sisters! My name is Ben van Gelderen. I'm married to Kate and we have five kids. I was born in Sydney, but Kate was born in Darwin and grew up in Numbulwar as her parents were Bible translators. We always thought God may call us to the NT and in 2009 we went to live at Gäwa, working at the small, homeland Christian school there. Now I work at Nungalinya in Darwin and it is a great joy to fellowship with so many Elders and church leaders. To me, KCC is a wonderful opportunity to learn and grow together- and Jesus is the source of it all!</p>
                  </blockquote>

                  <p>
                    Please bring your Bibles to the teaching times. A time of prayer will be held 30 minutes before and after each session.
                  </p>
                </div>
              </div>
              <div className="col-lg-6">
              <section className="speakers-area-two">
                <div className="elkevent-single-speakers">
                  <img src={require("../../assets/images/Ben_G.jpg")} alt="Speaker 2024" />

                  <div className="speakers-content">
                      <h3><Link to="#">Ben van Gelderen</Link></h3>
                      <span>Principal of Nungalinya College</span>
                  </div>
                </div>
              </section>
              </div>
            </div>
          </div>           
        );
    }
}
 
export default Speaker;