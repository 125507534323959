import React from 'react';
import lax from 'lax.js';
import LaxButton from '../Shared/LaxButton';
import { Link } from 'react-router-dom';

class About extends React.Component {

  constructor(props) {
    super(props)
    lax.setup()

    document.addEventListener('scroll', function (x) {
      lax.update(window.scrollY)
    }, false)

    lax.update(window.scrollY)
  }

  render() {
    return (
      <section className="about-area-two ptb-120 bg-image">
        <div className="container">
          <div className="row h-100 align-items-center">
            <div className="col-lg-6">
              <div className="about-content">
                <span>Volunteer</span>
                <h2>Are you ready to <b>volunteer</b> at KCC 2024?</h2>

                <p>Volunteers have been supporting the work of KCC for over 50 years and make a big difference.</p>

                <p>We are looking for groups and/or individuals who are willing to serve during the convention.  We need people in the following areas:
                  <ul>
                    <li>Welcoming and registration</li>
                    <li>Preparation of food</li>
                    <li>Cleaning – hygiene and removal of rubbish</li>
                    <li>Coming early to prepare</li>
                    <li>Main Program</li>
                  </ul>
                </p>

                <p>If you are interested in volunteering at KCC 2024, then please &nbsp;    
                  <Link to="/contact" className="contact-authority">
                      contact us
                  </Link>, before the 1st April, 2024.
                </p>

                <p>
                  <i>Volunteers are required to fill in an application form, provide pastoral references and complete Child Safety training prior to KCC.</i>
                </p>

                <p><a href={require("../../assets/downloads/KCC_CSE3-OA_Volunteer_Application_Form.pdf")} alt="Download the Volunteer Application Form" download fileName="KCC_CSE3-OA Volunteer_Application_Form.pdf" type="application/pdf">Download the Volunteer Application Form</a>.</p>

              </div>
            </div>

            <div className="col-lg-6">
              <div className="about-image">
                <img src={require("../../assets/images/evenings_1.png")} className="about-img1" alt="Volunteer" />

                <LaxButton buttonText="Download the Volunteer Application Form" downloadLocation={require("../../assets/downloads/KCC_CSE3-OA_Volunteer_Application_Form.pdf")} downloadName="KCC_CSE3-OA_Volunteer_Application_Form.pdf" downloadType="application/pdf" altText="Download the Volunteer Application Form" />
                
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default About;