import React from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import lax from 'lax.js';
 
class LaxButton extends React.Component {

    componentDidMount() {
        this.el = ReactDOM.findDOMNode(this)
        lax.addElement(this.el)
    }
    
    componentWillUnmount() {
        lax.removeElement(this.el)
    }

    render(){
        return (
          <>
            {this.props.downloadLink !== '#' 
            ?
              <a download href={this.props.downloadLocation} fileName={this.props.downloadName} alt={this.props.altText} type={this.props.downloadType} className="btn btn-primary lax" data-lax-preset="driftLeft">{this.props.buttonText}</a>
            : 
            <Link to={this.props.linkLocation} alt={this.props.altText} className="btn btn-primary lax" data-lax-preset="driftLeft">
                {this.props.buttonText}
            </Link>
            }
            
          </>
        );
    }
}

LaxButton.defaultProps = {
    buttonText: 'Explore More About',
    downloadLocation: '#',
    downloadName: '',
    downloadType: '',
    linkLocation: '#',
    altText: ''
};
 
export default LaxButton;