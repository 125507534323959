import React from 'react'
import MainBanner from '../Volunteer/MainBanner';
import About from '../Volunteer/About';
import Partner from '../Common/Partner';
import Footer from '../Common/Footer';
import GoTop from '../Shared/GoTop';
import Cta from '../Common/Cta';

class Volunteer extends React.Component {
  render() {
    return (
      <React.Fragment>
        <MainBanner />
        <About />
        <Cta />
        <Partner />
        <Footer />
        <GoTop scrollStepInPx="50" delayInMs="16.66" />
      </React.Fragment>
    );
  }
}

export default Volunteer;