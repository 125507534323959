import React from 'react';
import { Link } from 'react-router-dom';

class About extends React.Component {
  render() {
    return (
      <section className="about-area-three ptb-120 bg-image">
        <div className="container">
          <div className="row h-100 align-items-center">
            <div className="col-lg-6">
              <div className="about-image">
                <img
                  src={require("../../assets/images/evenings_7.png")}
                  className="about-img1"
                  alt="Membership"
                />
              </div>
            </div>

            <div className="col-lg-6">
              <div className="about-content">
                <span>Membership</span>
                <h2>Would you like to get <b>more involved</b> in KCC?</h2>
                
                <p>Becoming a member is a valuable way to show your support for the convention. If you are interested, then please <Link to="/contact" className="contact-authority">Contact Us</Link>.</p>
                <br /> 
                <br /> 
                <p>Also, here are a couple of membership documents:
                  <ul>
                    <li>
                      <i className="icofont-long-arrow-right"></i>
                      <a href={require("../../assets/downloads/KCC_Membership_Application_Form_2023.docx")} alt="Membership Application Form" download fileName="KCC_Membership_Application_Form_2023" type="application/vnd.openxmlformats-officedocument.wordprocessingml.document">Application for KCC Membership or Associate</a>
                    </li>
                    <li>
                      <i className="icofont-long-arrow-right"></i>
                      <a href={require("../../assets/downloads/KCC_Member_Renewal_Form_2023.docx")} alt="Membership Renewal Form" download fileName="KCC_Member_Renewal_Form_2023.docx" type="application/vnd.openxmlformats-officedocument.wordprocessingml.document">Renew your KCC Membership or Associate</a>
                    </li>                    
                    <li>
                      <i className="icofont-long-arrow-right"></i>
                      <a href={require("../../assets/downloads/KCC_New_Constitution_ver 3.4_2020.pdf")} alt="Constitution" download fileName="KCC_New_Constitution_ver 3.4_2020.pdf" type="application/pdf">Constitution</a>
                    </li>
                  </ul>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default About;