import lax from 'lax.js';
import React from 'react';
import LaxButton from '../Shared/LaxButton';

class About extends React.Component {

  constructor(props) {
    super(props)
    lax.setup()

    document.addEventListener('scroll', function (x) {
      lax.update(window.scrollY)
    }, false)

    lax.update(window.scrollY)
  }

  render() {
    return (
      <section className="about-area-three ptb-120 bg-image">
        <div className="container">
          <div className="row h-100 align-items-center">
            

            <div className="col-lg-6">
              <div className="about-content">
                <span>This year</span>
                <h2>"Jesus is the Living Water"</h2>
                <h6>Tell everyone about KCC 2024!</h6>
                <p>Please <a href={require("../../assets/downloads/KCC_2024_Flyer.pdf")} alt="Download 2024 Flyer" download fileName="KCC_2024_Flyer.pdf" type="application/pdf">download the 2024 flyer here</a>.</p>
                
                <br />
                <h6>Details</h6>
                <ul>
                  <li>
                    <i className="icofont-long-arrow-right"></i>
                    Friday 3rd - Sunday 5th May 2024
                  </li>
                  <li>
                    <i className="icofont-long-arrow-right"></i>
                    <a href="https://goo.gl/maps/EUTKo1ma3Sjrn6BU8" target="_blank" rel="noopener noreferrer">Katherine Showgrounds, Murphy Street, Katherine, Northern Territory</a>
                  </li>
                </ul>
                <br />
                <h6>Prices</h6>
                <p>You're welcome to come for one day or both. Just register on the day. Payments can be by cash, debit, credit and basics cards.</p>
                <ul>
                  <li>
                    <i className="icofont-long-arrow-right"></i>
                    Adults: $20 for 1 day / $30 for 3 days
                  </li>
                  <li>
                    <i className="icofont-long-arrow-right"></i>
                    School age children: $10 for 1 day / $15 for 2 days
                  </li>
                  <li>
                    <i className="icofont-long-arrow-right"></i>
                    Under school age children: Free
                  </li>
                  <li>
                    <i className="icofont-long-arrow-right"></i>
                    Families (2 adults and 4 school age children): $90 for 3 days / $50 for 1 day.<br />
                    Payment can be by cash, debit, credit and basics cards.
                  </li>
                </ul>

                <br />
                <h6>Canteen and stalls</h6>
                <ul>
                  <li>
                    <i className='icofont-long-arrow-right'></i>
                    Food will be available to buy from the KCC Canteen or you are welcome to bring your own.
                  </li>

                  <li>
                    <i className='icofont-long-arrow-right'></i>
                    AuSIL will again be providing Bibles, Dictionaries, CDs and other materials in language.
                  </li>

                  <li>
                    <i className='icofont-long-arrow-right'></i>
                    Katherine Christian Giftshop will also have English bibles and other materials.
                  </li>

                  <li>
                    <i className='icofont-long-arrow-right'></i>
                    Paintings/Artworks will be available for purchase from Nungalinya College Art and Faith class students.
                  </li>

                  <li>
                    <i className='icofont-long-arrow-right'></i>
                    Mission Focus 2024 – Our mission this year will be AuSIL, so expect to hear about the work of AuSIL throughout KCC 2024.
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="about-image buy-tickets">
                <img
                  src={require("../../assets/images/living_water_sml.jpg")}
                  className="about-img1"
                  alt="about"
                />

                <LaxButton buttonText="Download 2024 Flyer" downloadLocation={require("../../assets/downloads/KCC_2024_Flyer.pdf")} downloadName="KCC_2024_Flyer.pdf" downloadType="application/pdf" altText="Download 2024 Flyer" />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default About;