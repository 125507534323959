import React from 'react';

class MainBanner extends React.Component {
  render() {
    return (
      <div className="page-title-area item-past-speakers">
        <div className="container">
          <h1>Past Speakers</h1>
        </div>
      </div>
    );
  }
}

export default MainBanner;