import React from 'react';
import lax from 'lax.js';
import OwlCarousel from 'react-owl-carousel3';

const options = {
  loop: true,
  nav: false,
  dots: false,
  autoplayHoverPause: true,
  autoplay: true,
  navText: [
    "<i class='icofont-rounded-left'></i>",
    "<i class='icofont-rounded-right'></i>"
  ],
  responsive: {
    0: {
      items: 2,
    },
    400: {
      items: 2,
    },
    576: {
      items: 3,
    },
    768: {
      items: 3,
    },
    1200: {
      items: 4,
    }
  }
}

class Partner extends React.Component {

  constructor(props) {
    super(props)
    lax.setup()

    document.addEventListener('scroll', function (x) {
      lax.update(window.scrollY)
    }, false)

    lax.update(window.scrollY)
  }

  render() {
    return (
      <section className="partner-area ptb-120">
        <div className="container">
          <div className="section-title">
            <span>Check Who Makes This Event Possible</span>
            <h2>Our Event <b>Partners</b></h2>

            <div className="bar"></div>
          </div>

          <div className="row">
            <OwlCarousel
              className="platinum-partner-slides owl-carousel owl-theme"
              {...options}
            >
              <div className="col-lg-12 col-md-12">
                <div className="partner-item">
                  <a href="https://www.bushchurchaid.com.au/index.php" target="_blank" rel="noopener noreferrer">
                    <img src={require("../../assets/images/BCA_logo.png")} alt="Bush Church Aid" />
                    <img src={require("../../assets/images/BCA_logo.png")} alt="Bush Church Aid" />
                  </a>
                </div>
              </div>

              <div className="col-lg-12 col-md-12">
                <div className="partner-item">
                  <a href="https://wycliffe.org.au" target="_blank" rel="noopener noreferrer">
                    <img src={require("../../assets/images/Wycliffe_logo.png")} alt="Wycliffe Bible Translators" />
                    <img src={require("../../assets/images/Wycliffe_logo.png")} alt="Wycliffe Bible Translators" />
                  </a>
                </div>
              </div>

              <div className="col-lg-12 col-md-12">
                <div className="partner-item">
                  <a href="https://missionsinterlink.org.au" target="_blank" rel="noopener noreferrer">
                    <img src={require("../../assets/images/Missions_Interlink_logo.png")} alt="Missions Interlink" />
                    <img src={require("../../assets/images/Missions_Interlink_logo.png")} alt="Missions Interlink" />
                  </a>
                </div>
              </div>

              <div className="col-lg-12 col-md-12">
                <div className="partner-item">
                  <a href="https://su.org.au" target="_blank" rel="noopener noreferrer">
                    <img src={require("../../assets/images/ScriptureUnion_logo_lg.png")} alt="Scripture Union Australia" />
                    <img src={require("../../assets/images/ScriptureUnion_logo_lg.png")} alt="Scripture Union Australia" />
                  </a>
                </div>
              </div>              
            </OwlCarousel>

            <div className="col-lg-12">
              <div className="border"></div>
            </div>

          </div>
        </div>
      </section>
    );
  }
}

export default Partner;