import React from 'react';
// import { Link } from 'react-router-dom';
import 'isomorphic-fetch';
import Footer from '../Common/Footer';
import Reaptcha from 'reaptcha';

class Contact extends React.Component {
  state = {
    submitting: false,
    submitted: false,
    buttonState: '',
    formFields: {
      firstName: '',
      lastName: '',
      email: '',
      mobile: '',
      subject: '',
      message: ''
    },
    verified: false
  };

  onVerify = recaptchaResponse => {
    if (recaptchaResponse) {
      this.setState({
        verified: true
      });
    }
  };

  onSubmit = (e) => {
    e.preventDefault();
    const data = this.state.formFields;

    console.log('Submitting - data:', JSON.stringify(data)); 

    this.setState({ submitted: true }); 

    try {
      fetch('https://wixcg2nkre6ucakwmeqduwakbu0eokkn.lambda-url.ap-southeast-2.on.aws/', {
        mode: 'no-cors',  
        method: 'post',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      }).then(res => {
        if (res.status === 200) {
          console.log('Submitted successfully!');
          this.setState({ submitted: true })
        } else {
  
        }
        let formFields = Object.assign({}, this.state.formFields);
        formFields.firstName = '';
        formFields.lastName = '';
        formFields.email = '';
        formFields.mobile = '';
        formFields.subject = '';
        formFields.message = '';
        this.setState({ formFields });
        this.captcha.reset();
      });
    } catch (error) {
      console.log('Error occured: ', error); 
    }
    
  }

  firstNameChangeHandler = (e) => {
    let formFields = Object.assign({}, this.state.formFields);
    formFields.firstName = e.target.value;
    this.setState({ formFields });
  }

  lastNameChangeHandler = (e) => {
    let formFields = Object.assign({}, this.state.formFields);
    formFields.lastName = e.target.value;
    this.setState({ formFields });
  }

  emailChangeHandler = (e) => {
    let formFields = Object.assign({}, this.state.formFields);
    formFields.email = e.target.value;
    this.setState({ formFields });
  }

  ccMessageChangeHandler = (e) => {
    let formFields = Object.assign({}, this.state.formFields);
    console.log('ccMessageChangeHandler: e.target.checked:', e.target.checked);
    formFields.ccMessage = e.target.checked;
    console.log('ccMessageChangeHandler: formFields:', JSON.stringify(this.state.formFields));
    this.setState({ formFields });
  }

  mobileChangeHandler = (e) => {
    let formFields = Object.assign({}, this.state.formFields);
    formFields.mobile = e.target.value;
    this.setState({ formFields });
  }

  subjectChangeHandler = (e) => {
    let formFields = Object.assign({}, this.state.formFields);
    formFields.subject = e.target.value;
    this.setState({ formFields });
  }

  messageChangeHandler = (e) => {
    let formFields = Object.assign({}, this.state.formFields);
    formFields.message = e.target.value;
    this.setState({ formFields });
  }

  onHideSuccess = () => {
    this.setState({ submitted: false });
  }

  successMessage = () => {
    if (this.state.submitted) {
      return (
        <div
          className="alert alert-success alert-dismissible fade show"
          style={{ marginTop: '14px' }}
        >
          <strong>Thank you!</strong> Your message has been sent.
          <button
            type="button"
            className="close"
            onClick={this.onHideSuccess}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      );
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-title-area item-contact">
          <div className="container">
            <h1>Contact Us</h1>
            <span>Drop us a line</span>
          </div>
        </div>

        <section className="contact-area ptb-120">
          <div className="container">
            <div className="row h-100 align-items-center contact-form">
              <div className="col-lg-4 col-md-12">
                <div className="leave-your-message">
                  <h3>Send us a Message</h3>
                  <p>If you have any questions about the Conference, please fill in the form. We try and respond to all queries and comments quickly.</p>

                  <div className="stay-connected">
                    <h3>Stay Connected</h3>
                    <ul>
                      <li>
                        <a href="https://www.facebook.com/groups/KatherineCC" className="facebook" target="_blank" rel="noopener noreferrer">
                          <i className="icofont-facebook"></i>
                          <span>Facebook</span>
                        </a>
                      </li>
                      <li>
                        <a href="https://www.youtube.com/@katherinechristianconventi7216" className="video-btn popup-youtube" target="_blank" rel="noopener noreferrer">
                          <i className="icofont-ui-play"></i>
                          <span>YouTube</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-lg-8 col-md-12">
                <form onSubmit={this.onSubmit} id="contactForm">
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label htmlFor="name">First Name*</label>
                        <input
                          type="text"
                          className="form-control"
                          name="firstName"
                          id="firstName"
                          required={true}
                          data-error="Please enter your first name"
                          value={this.state.formFields.firstName}
                          onChange={this.firstNameChangeHandler}
                        />
                        <div className="help-block with-errors"></div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label htmlFor="name">Last Name*</label>
                        <input
                          type="text"
                          className="form-control"
                          name="lastName"
                          id="lastName"
                          required={true}
                          data-error="Please enter your last name"
                          value={this.state.formFields.lastName}
                          onChange={this.lastNameChangeHandler}
                        />
                        <div className="help-block with-errors"></div>
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label htmlFor="email">Email*</label>
                        <input
                          type="email"
                          className="form-control"
                          name="email"
                          id="email"
                          required={true}
                          data-error="Please enter your email"
                          value={this.state.formFields.email}
                          onChange={this.emailChangeHandler}
                        />
                        <div className="help-block with-errors"></div>
                      </div>
                    </div>

                    {/* <div className="col-lg-6 col-md-6" style={{ 'display': 'flex', 'flex-wrap': 'wrap','align-items': 'center' }}>
                      <div className="form-group-x form-check" >
                          <label htmlFor="ccMessage" className="form-check-label">Email me a copy</label>
                          <input
                            type="checkbox"
                            className="form-check-input"
                            name="ccMessage"
                            id="ccMessage"
                            checked={this.state.formFields.ccMessage}
                            value={this.state.formFields.ccMessage}
                            onChange={this.ccMessageChangeHandler}
                          />
                          <div className="help-block with-errors"></div>
                        
                      </div>
                    </div> */}

                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label htmlFor="number">Mobile Number*</label>
                        <input
                          type="text"
                          className="form-control"
                          name="mobile"
                          id="mobile"
                          required={true}
                          data-error="Please enter your mobile number"
                          value={this.state.formFields.mobile}
                          onChange={this.mobileChangeHandler}
                        />
                        <div className="help-block with-errors"></div>
                      </div>
                    </div>

                    <div className="col-lg-12 col-md-12">
                      <div className="form-group">
                        <label htmlFor="number">Subject*</label>
                        <input
                          type="text"
                          className="form-control"
                          name="subject"
                          id="subject"
                          required={true}
                          data-error="Please say what your enquiry is about"
                          value={this.state.formFields.subject}
                          onChange={this.subjectChangeHandler}
                        />
                        <div className="help-block with-errors"></div>
                      </div>
                    </div>

                    <div className="col-lg-12 col-md-12">
                      <div className="form-group">
                        <label htmlFor="message">Message*</label>
                        <textarea
                          name="message"
                          className="form-control"
                          id="message"
                          cols="30"
                          rows="4"
                          required={true}
                          data-error="Please enter your message"
                          value={this.state.formFields.message}
                          onChange={this.messageChangeHandler}
                        />
                        <div className="help-block with-errors"></div>
                      </div>
                    </div>

                    <div className="col-lg-12 col-md-12">
                      <div className="form-group">
                        <Reaptcha sitekey="6Lfyv5gkAAAAAIO_Upo9LBfk2iVA3xptfxlxhMp8" onVerify={this.onVerify} ref={e => (this.captcha = e)} />
                      </div>
                    </div>

                    <div className="col-lg-12 col-md-12">
                      <button type="submit" className="btn btn-primary" disabled={!this.state.verified}>Send Message</button>
                      <div id="msgSubmit" className="h3 text-center hidden">
                        {this.successMessage()}
                      </div>
                      <div className="clearfix"></div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </React.Fragment>
    );
  }
}

export default Contact;