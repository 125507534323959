import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Navigation from "./components/Navigation/Navigation";
import Donate from "./components/pages/Donate";
import Membership from "./components/pages/Membership";
import PastSpeakers from "./components/pages/PastSpeakers";
import Volunteer from "./components/pages/Volunteer";
import Contact from "./components/pages/Contact";
import HomeThree from "./components/pages/HomeThree";
import NotFound from "./components/pages/NotFound";

// Conditionally render Navigation
const renderNavigation = () => {
    if (!(window.location.pathname === '/error-404')){
        return <Navigation />;
    }
}

const AppRouter = () => {
    return (
        <Router>
            {renderNavigation()}
            <Switch>
                <Route path="/" exact component={HomeThree} />
                <Route path="/volunteer" exact component={Volunteer} />
                <Route path="/past-speakers" exact component={PastSpeakers} />
                <Route path="/membership" exact component={Membership} />
                <Route path="/donate" exact component={Donate} />
                <Route path="/contact" exact component={Contact} />                
                <Route path="/error-404" exact component={NotFound} />
                <Route component={NotFound} />
            </Switch>
        </Router>
    );
};

export default AppRouter;