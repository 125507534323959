import React from 'react';
import { Link } from 'react-router-dom';

class Footer extends React.Component {
  render() {
    let currentYear = new Date().getFullYear();
    return (
      <footer className="footer-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <div className="single-footer-widget">
                <h3>Details</h3>
                <span>
                  <i className="icofont-calendar"></i> 3rd-5th May, 2024
                </span>

                <p className="location">
                  <i className="icofont-google-map"></i> <a href="https://goo.gl/maps/EUTKo1ma3Sjrn6BU8" target="_blank" rel="noopener noreferrer">Katherine Showgrounds, Murphy Street , Katherine, Northern Territory</a>
                </p>

                <Link to="/contact" className="contact-authority">
                  <i className="icofont-phone"></i> Contact Us
                </Link>

                <ul className="social-links">
                  <li>
                    <a href="https://www.facebook.com/groups/KatherineCC" className="facebook" target="_blank" rel="noopener noreferrer">
                      <i className="icofont-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.youtube.com/@katherinechristianconventi7216" className="video-btn popup-youtube" target="_blank" rel="noopener noreferrer">
                      <i className="icofont-ui-play"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6 col-md-12" style={{ 'display': 'flex', 'justify-content': 'center' }}>
              <img src={require("../../assets/images/All_one_Logo_200.png")} alt="logo" />
            </div>

            <div className="col-lg-12">
              <div className="copyright-area">
                <div className="logo">
                  <Link to="/">
                    <img src={require("../../assets/images/KCC_Logo_alt.png")} alt="logo" />
                  </Link>
                </div>
                <ul>
                  <li><Link to="/contact">Contact Us</Link></li>
                  <li><a href="https://goo.gl/maps/EUTKo1ma3Sjrn6BU8" target="_blank" rel="noopener noreferrer">Venue</a></li>
                </ul>
                <p>
                  Copyright <i className="icofont-copyright"></i> {currentYear} Katherine Christian Convention. All rights reserved
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;